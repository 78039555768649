import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/store'
Vue.use(VueRouter)

const isLoggedIn = () => {
  let exp = localStorage.getItem("exp")
  if (exp !== null && Date.parse(exp) >= Date.now())
    return true;
  store.dispatch('authStore/logout', true)
  return false
}
const hasAdminRole = (to, from, next) => {
  // note: check local store as vuex storage is created after route
  let roles = localStorage.getItem("roles")
  if (isLoggedIn() && roles !== null && roles.includes('admin')) {
    next()
    return
  }
  next({
    path: "/login", query: {redirect: to.fullPath}
  })
}

const hasSysAdminRole = (to, from, next) => {
  let roles = localStorage.getItem("roles")
  if (isLoggedIn() && roles !== null && roles.includes('sysadmin')) {
    next()
    return
  }
  next('/login')
}

const routes = [
  {
    path: '/',
    name: '/',
    component: Home
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/albums',
    name: 'albums',
    component: () => import(/* webpackChunkName: "album" */ '../views/Album.vue'),
    beforeEnter: hasAdminRole,
  },
  {
    path: '/tools',
    name: 'tools',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Tools.vue'),
    beforeEnter: hasAdminRole,
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import(/* webpackChunkName: "customers" */ '../views/Customer.vue'),
    beforeEnter: hasAdminRole,
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import(/* webpackChunkName: "statistic" */ '../views/Statistic.vue'),
    beforeEnter: hasAdminRole,
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/Order.vue'),
    beforeEnter: hasAdminRole,
  },
  {
    path: '/system',
    name: 'system',
    component: () => import(/* webpackChunkName: "system" */ '../views/System.vue'),
    beforeEnter: hasSysAdminRole,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/user/Login.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "logout" */ '../views/user/Logout.vue')
  },
  {
    path: '/preferences',
    name: 'preferences',
    component: () => import(/* webpackChunkName: "preferences" */ '../views/Preference.vue'),
  },
  {
    path: '/change_pwd',
    name: 'change_pwd',
    component: () => import(/* webpackChunkName: "change_pwd" */ '../views/user/ChangePwd.vue'),
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.onError(() => {  })
export default router
