function cleanObject(obj) {
  for (let k in obj) {
    if (typeof obj[k] == "object"
        && obj[k] !== null
        && !(obj[k] instanceof Array)
        && !(obj[k] instanceof String)
        && !(obj[k] instanceof Number)) {

      cleanObject(obj[k]);
      continue;
    }

    switch (typeof obj[k]) {
      case 'string':
      case 'number':
        delete obj[k];
        break;

      default:
        obj[k] = [];
    }
  }
}

// sendGlobalMessage display an application wide message, the 'GlobalMessageBox' component is placed in the App view
function sendGlobalMessage(msg) {
  let hmwEvent = new Event('hwm-event-msg')
  hmwEvent.msg = msg
  document.dispatchEvent(hmwEvent);
}

module.exports = {
  cleanObject: cleanObject,
  sendGlobalMessage: sendGlobalMessage
}