<template>
  <v-app>
    <v-app-bar app dense>
      <v-img alt="Heavyweight Music Logo" class="shrink mr-2" contain
             src="../src/assets/images/logo-heavyweight-192.png"
             transition="scale-transition"
             width="40"
      />
      <v-toolbar-title>Heavyweight Music</v-toolbar-title>
      <v-spacer></v-spacer>
      <GlobalMessageBox ref="globalMsgBox" id="globalMsgBox"></GlobalMessageBox>
      <nav>
        <v-btn text rounded to="/home">Home</v-btn>
        <v-btn text rounded v-show="hasAdminRole" :to="{name:'albums'}">Albums</v-btn>
        <v-menu v-show="hasAdminRole" offset-y >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text rounded v-show="hasAdminRole" v-bind="attrs" v-on="on" >
             Sales
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn text rounded :to="{name:'customers'}">Customers</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text rounded :to="{name:'orders'}">Orders</v-btn>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-btn text rounded :to="{name:'statistics'}">Statistics</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn text rounded v-show="hasAdminRole" :to="{name:'tools'}">Tools</v-btn>
        <v-btn text rounded v-show="hasSysAdminRole" :to="{name:'system'}">System</v-btn>
        <v-btn text rounded v-show="!hasAdminRole" :to="{name:'login'}">Login</v-btn>
        <v-menu v-show="hasAdminRole" offset-y >
          <template v-slot:activator="{ on, attrs }">
            <v-avatar text rounded v-show="hasAdminRole" v-bind="attrs" v-on="on">
              <v-icon dark>
                mdi-account-circle
              </v-icon>
            </v-avatar>
          </template>
          <v-list>
            <v-list-item>
              <v-btn text rounded :to="{name:'preferences'}">Preferences</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text rounded :to="{name:'change_pwd'}">Change Password</v-btn>
            </v-list-item>
              <v-divider></v-divider>
            <v-list-item>
              <v-btn text rounded :to="{name:'logout'}">Logout</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </nav>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer absolute class="font-weight-medium">
      <v-layout justify-center wrap>
        (c) Heavyweight Music 2020
      </v-layout>

    </v-footer>
  </v-app>
</template>

<script>

import GlobalMessageBox from "@/components/GlobalMessageBox";

export default {
  name: 'App',
  components: {
    GlobalMessageBox
  },
  data: () => ({
    links: [
      {label: 'Home', url: '/'},
      {label: 'Login', url: '/login'},
      {label: 'Logout', url: '/logout'},
    ]
    //
  }),
  methods: {
    unauthorizedHandler: function () {
      this.$refs.globalMsgBox.showUnauthorized();
    },
    messageHandler: function (event) {
      this.$refs.globalMsgBox.showMessage(event.msg);
    }
  },
  created() {
    // check if valid credentials are available
    if (localStorage.getItem("user")) {
      let strDate = localStorage.getItem("exp")
      let expDate = Date.parse(strDate)
      if (! expDate - new Date().getTime() > 1) {
        this.$store.dispatch('authStore/logout',true)
      } else {
        this.$store.commit('authStore/LOGIN')  // fill vuex store
      }
    }
    document.addEventListener('hwm-event-unauthorized', this.unauthorizedHandler);
    document.addEventListener('hwm-event-msg', this.messageHandler);
  },
  computed: {
    hasAdminRole() {
      return this.$store.getters["authStore/hasAdminRole"]
    },
    hasSysAdminRole() {
      return this.$store.getters["authStore/hasSysAdminRole"]
    }
  }
};
</script>
