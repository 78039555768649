// noinspection DuplicatedCode
import CustomerService from '@/services/customerService'

export const namespaced = true

export const state = {
  customers: {
    fetched: [], // fetched customer are stored in general store
    total: 0
  },
  customerDetail: {},  // full customer details currently displayed/edited

}
export const mutations = {
  CLEAR_CUSTOMERS(state) {
    state.customers.fetched = []
    state.customers.total = 0
  },
  LOAD_CUSTOMERS(state, result) {
    state.customers.fetched = result.customers
    state.customers.total = result.total
  },

}

export const actions = {
  clearCustomers({commit}) {
    commit('CLEAR_CUSTOMERS')
  },
  fetchCustomers({commit}, args) {
    let query = ""
    if (args.filter) {
      query += "search_field=" + args.filter.field
      query += "&search_txt=" + args.filter.value+ "&"
    }
    if (args.sort) {
      query += "sort=" + args.sort
      query += "&sort_order=" + (args.sort_desc == true ? "D" : "A")
    }
   return CustomerService.getCustomers(query, args.skip, args.limit)
      .then(response => {
        commit('LOAD_CUSTOMERS', response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  fetchCustomer({commit}, customerId) {
    return CustomerService.getCustomer(customerId)
      .then(response => {
        commit('EDIT_CUSTOMER', response.data);
      })
      .catch(error => {
        commit('EDIT_CUSTOMER', {});
        return Promise.reject(error);
      });
  },

}

export const getters = {
  getCustomers: (state) => {
    return state.customers.fetched
  },
  getCustomersTotal: (state) => {
    return state.customers.total
  },
  getCustomer: (state) => {
    return state.customerDetail
  },

}