import apiClient from "@/services/apiClient";
import store from "@/store/store";

export const namespaced = true // prevent naming conflicts with other modules

export const state = {
  token: '',
  user: '',
  roles: '',
  exp: null,
}

export const mutations = {
  LOGIN(state) {
    state.token = localStorage.getItem("token")
    state.user = localStorage.getItem("user")
    state.exp = localStorage.getItem("exp")
    state.roles = localStorage.getItem("roles")
  },
  LOGOUT(state) {
    state.token = ''
    state.user = ''
    state.exp = ''
    state.roles = ''
  },
}

export const actions = {
  // eslint-disable-next-line no-unused-vars
  changePwd({commit},cred) {
    return apiClient.put('/auth/passwords', cred)
      // eslint-disable-next-line no-unused-vars
      .then(function (response) {

      })
      .catch(function (error) {
        if (process.env.NODE_ENV === "development")
          console.error('*** Change pwd error: ' + error);
        throw(error);
      });
  },
  login({commit}, cred) {
    return apiClient.post('/auth/login', {name: cred.email, password: cred.pwd})
      .then(function (response) {
        setToken(response.data);
        commit('LOGIN')
      })
      .catch(function (error) {
        if (process.env.NODE_ENV === "development")
          console.error('*** Login error: ' + error);
        throw(error);
      });
  },
  logout({commit}, flag) {
    localStorage.clear();
    commit('LOGOUT')
    if (flag) {
      // event handling taking place in App.vue.
      document.dispatchEvent(new Event('hwm-event-unauthorized'));
    }
  },
  // eslint-disable-next-line no-unused-vars
  register({commit}, user) {

  }
}

export const getters = {
  isLoggedIn: () => {
    let time = state.exp;
    if (time == null)
      return false;
    return new Date() < new Date(time);
  },
  user: () => {
    return state.user;
  },
  hasAdminRole:() =>  {
    return (state.roles.includes("admin"))
  },
  hasSysAdminRole:() =>  {
    return (state.roles.includes("sysadmin"))
  }
}

function setToken(claim) {
  localStorage.setItem("token", claim.token);
  localStorage.setItem("user", claim.user);
  localStorage.setItem("roles", claim.roles);
  let expDate = new Date(claim.exp * 1000);
  localStorage.setItem("exp", expDate.toString());
  // refresh the token if application still open
  let diff = Math.abs(expDate.getTime() - new Date().getTime());
  setTimeout(refresh, diff - (60 * 1000));
  if (process.env.NODE_ENV === "development")
    console.log("*** refresh after " + (diff - (60 * 1000)) / 1000 + " seconds ***")
}

function refresh() {
  if (localStorage.getItem("token") !== null) {
    apiClient.get('/auth/refresh')
      .then(response => {
        setToken(response.data);
        console.log("*** New Token set***" + new Date().toISOString());
      })
      // eslint-disable-next-line no-unused-vars
      .catch(function (err) {
        if (process.env.NODE_ENV === "development")
          console.log('Refresh error occurred-> store/logout triggered. Reason='+err);
        store.dispatch('authStore/logout', false)
      });
  }
}
