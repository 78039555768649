import apiClient from "@/services/apiClient";

export default {
  createAlbum(album) {
    return apiClient.post('/albums', album);
  },

  deleteAlbum(arg) {
    let delFlag = "no"
    if (arg.deleteOnChannels)
      delFlag = "yes"
    return apiClient.delete('/albums/' + arg.album_id + "?channels=" + delFlag);
  },

  getAlbums(queryStr, skip, limit) {
    let urlParam = ''
    if (queryStr.length > 0)
      urlParam = '?' + queryStr
    if (skip || limit) {
      if (urlParam === '')
        urlParam = '?'
      else
        urlParam = urlParam + '&'
      urlParam = urlParam + "skip=" + skip + "&limit=" + limit
    }
    return apiClient.get('/albums' + urlParam);
  },

  getAlbum(id) {
    return apiClient.get('/albums/' + id);
  },

  getAlbumFromDiscogs(release, barcode, catNo, format, year, country) {
    let qp = "";
    if (release !== "")
        // search with a release id
      qp = "release=" + release
    else {
      // search by barcode
      qp = "barcode=" + barcode
      if (catNo)
        qp = qp + "&catno=" + catNo
      if (format)
        qp = qp + "&format=" + format
      if (year)
        qp = qp + "&year=" + year
      if (country)
        qp = qp + "&country=" + country
    }
    return apiClient.get('/discogs/releases?' + qp);
  },

  getConditions(condType) {
    return apiClient.get('/albums/conditions/' + condType);
  },

  getGenres() {
    return apiClient.get('/albums/genres/');
  },
  publish(channelInfo) {
    return apiClient.put('/albums/' + channelInfo.album_id + '/channels/' + channelInfo.channel + '?publish=' + channelInfo.published);
  },

  unPublish(channelInfo) {
    return apiClient.delete('/albums/' + channelInfo.album_id + '/channels/' + channelInfo.channel);
  },
  updateAlbum(album) {
    return apiClient.put('/albums/' + album.id, album);
  },
}