// noinspection DuplicatedCode
import OrderService from '@/services/orderService'

export const namespaced = true

export const state = {
  orders: {
    fetched: [], // fetched orders are stored in general store
    total: 0
  },
  orderDetail: {},  // full order details currently displayed/edited

}
export const mutations = {
  CLEAR_ORDERS(state) {
    state.orders.fetched = []
    state.orders.total = 0
  },
  LOAD_ORDERS(state, result) {
    state.orders.fetched = result.orders
    state.orders.total = result.total
  },

}

export const actions = {
  clearOrders({commit}) {
    commit('CLEAR_ORDERS')
  },
  fetchOrders({commit}, args) {
    let query = ""
    if (args.filter) {
      query += "search_field=" + args.filter.field
      query += "&search_txt=" + args.filter.value+ "&"
    }
    if (args.sort) {
      query += "sort=" + args.sort
      query += "&sort_order=" + (args.sort_desc == true ? "D" : "A")
    }
   return OrderService.getOrders(query, args.skip, args.limit)
      .then(response => {
        commit('LOAD_ORDERS', response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  fetchOrder({commit}, orderId) {
    return OrderService.getOrder(orderId)
      .then(response => {
        commit('EDIT_ORDER', response.data);
      })
      .catch(error => {
        commit('EDIT_ORDER', {});
        return Promise.reject(error);
      });
  },

}


export const getters = {
  getOrders: (state) => {
    return state.orders.fetched
  },
  getOrdersTotal: (state) => {
    return state.orders.total
  },
  getOrder: (state) => {
    return state.orderDetail
  },

}