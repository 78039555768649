import AlbumService from '@/services/albumService'

export const namespaced = true // prevent naming conflicts with other modules

export const state = {
  albums: {
    fetched: [], // fetched albums are stored in general store
    total: 0
  },
  albumDetail: {},  // full album currently displayed/edited
  formats: [], // allowed formats
  genres: [], // list of available genres
  mediaConditions: [], // list of media conditions
  salesChannels: ["discogs", "shopify"],
  sleeveConditions: [], // list of possible sleeveConditions
}
export const mutations = {
  CLEAR_ALBUMS(state) {
    state.albums.fetched = []
    state.albums.total = 0
  },
  LOAD_ALBUMS(state, result) {
    state.albums.fetched = result.albums
    state.albums.total = result.total
  },
  DELETE_ALBUM(state, id) {
    state.albums.fetched = state.albums.fetched.filter(item => item.id !== id)
  },
  EDIT_ALBUM(state, album) {
    state.albumDetail = album
  },
  LOAD_GENRES(state, genres) {
    state.genres = genres
  },
  LOAD_FORMATS(state, formats) {
    state.formats = formats
  },
  LOAD_MEDIA_CONDITIONS(state, conditions) {
    state.mediaConditions = conditions
  },
  LOAD_SLEEVE_CONDITIONS(state, conditions) {
    state.sleeveConditions = conditions
  }
}

export const actions = {
  clearAlbums({commit}) {
    commit('CLEAR_ALBUMS')
  },
  createAlbum({commit}, album) {
    return AlbumService.createAlbum(album)
      .then(response => {
        commit('EDIT_ALBUM', response.data)
        state.albums.fetched.unshift(response.data)
      })
      .catch(error => {
        console.log("*** Store album.createAlbum.Error=" + error);
        return Promise.reject(error);
      })
  },
  deleteAlbum({commit}, arg) {
    return AlbumService.deleteAlbum(arg)
      // eslint-disable-next-line no-unused-vars
      .then(commit('DELETE_ALBUM', arg.album_id))
      .catch(error => {
        return Promise.reject(error);
      })
  },
  fetchAlbums({commit}, args) {
    let query = ""
    if (args.filter.length > 0)
      query = args.filter.slice(0, -1);
    return AlbumService.getAlbums(query, args.skip, args.limit)
      .then(response => {
        commit('LOAD_ALBUMS', response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  fetchAlbum({commit}, albumId) {
    return AlbumService.getAlbum(albumId)
      .then(response => {
        commit('EDIT_ALBUM', response.data);
      })
      .catch(error => {
        commit('EDIT_ALBUM', {});
        return Promise.reject(error);
      });
  },
  fetchGenres({commit}) {
    if (state.genres.length === 0) {
      AlbumService.getGenres()
        .then(response => {
          commit('LOAD_GENRES', response.data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    }
  },
  fetchConditions({commit}) {
    if (state.mediaConditions.length === 0) {
      AlbumService.getConditions("M")
        .then(response => {
          commit('LOAD_MEDIA_CONDITIONS', response.data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    }
    if (state.sleeveConditions.length === 0) {
      AlbumService.getConditions("S")
        .then(response => {
          commit('LOAD_SLEEVE_CONDITIONS', response.data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    }
  },
  fetchFormats({commit}) {
    if (state.formats.length === 0) {
      let formats = ['LP', '7inch', '10inch', '12inch', 'CD', 'Cassette']
      commit('LOAD_FORMATS', formats)
    }
  },
  publishChannel({commit}, channelInfo) {
    return AlbumService.publish(channelInfo)
      .then(response => {
        let album = response.data
        commit('EDIT_ALBUM', album);
      })
      .catch(error => {
        return Promise.reject(error);
      })
  },
  unPublishChannel({commit}, channelInfo) {
    return AlbumService.unPublish(channelInfo)
      .then(response => {
        let album = response.data
        commit('EDIT_ALBUM', album);
      })
      .catch(error => {
        return Promise.reject(error);
      })
  },
  updateAlbum({commit}, album) {
    return AlbumService.updateAlbum(album)
      // eslint-disable-next-line no-unused-vars
      .then(response => {
        commit('EDIT_ALBUM', album);
      })
      .catch(error => {
        return Promise.reject(error);
      })
  },
}


export const getters = {
  getAlbums: (state) => {
    return state.albums.fetched
  },
  getAlbumsTotal: (state) => {
    return state.albums.total
  },
  getAlbum: (state) => {
    return state.albumDetail
  },
  getMediaConditions: (state) => {
    return state.mediaConditions
  },
  getSleeveConditions: (state) => {
    return state.sleeveConditions
  },
  getFormats: (state) => {
    return state.formats
  },
  getGenres: (state) => {
    return state.genres
  },
  getSalesChannels: (state) => {
    return state.salesChannels
  }
}
