<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="4">
        <a href="https://www.heavyweight-music.com">
          <v-img src="../../src/assets/images/heavyweight-com-logo.webp"
                 width="100%"></v-img>
        </a>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>


// @ is an alias to /src

export default {
  name: "HomePage",
  data: () => ({

    //
  }),
  beforeCreate: function () {

  },
  computed: {

  },

}
</script>

<style scoped>

</style>
