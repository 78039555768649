<template>
  <v-snackbar v-model="show" :absolute=true centered :bottom=true timeout="-1" >
    {{ message }}
    <v-btn text color="accent" @click.native=" show = false">Close</v-btn>
  </v-snackbar>

</template>

<script>

export default {
  name: "GlobalMessage",
  data: () => ({
    show: false,
    message: 'Hello Snackbar'
  }),
  methods: {
    showUnauthorized: function () {
      this.message ='Session expired or access not allowed. Please login again.'
      this.show = true
    },
    showMessage: function (msg) {
      this.message = msg
      this.show = true
    }
  },
}

</script>
