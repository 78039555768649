import Vue from 'vue'
import Vuex from 'vuex'
import * as albumStore from '@/store/modules/albumStore'
import * as customerStore from '@/store/modules/customerStore'
import * as orderStore from '@/store/modules/orderStore'
import * as authStore from '@/store/modules/authStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    albumStore: albumStore,
    customerStore: customerStore,
    orderStore: orderStore,
    authStore: authStore,
  },
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters: {

  }
})
