// noinspection DuplicatedCode
import apiClient from "@/services/apiClient";

export default {

  getCustomers(queryStr, skip, limit) {
    let urlParam = ''
    if (queryStr.length > 0)
      urlParam = '?' + queryStr
    if (skip || limit) {
      if (urlParam === '')
        urlParam = '?'
      else
        urlParam = urlParam + '&'
      urlParam = urlParam + "skip=" + skip + "&limit=" + limit
    }
    return apiClient.get('/customers' + urlParam);
  },

  getCustomer(id) {
    return apiClient.get('/customers/' + id);
  },
}