import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme : {
      dark: true,
      themes: {
        light: {
          primary: colors.pink,
          secondary: colors.grey.darken1,
          accent: colors.shades.black,
          error: colors.red.accent3,
        },
        dark: {
          primary: '#e91e63',
          warn: colors.red,
          accent : '#607d8b',
          error : colors.red.accent3,
        },
      },
    },
});
