import axios from 'axios';
import store from "@/store/store";
import helper from "@/services/helpers"

const instance = axios.create({
// .. where we make our configurations
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json', 'Content-Type': 'application/json',
  },
});

// Where you would set stuff like your 'Authorization' header, etc ...
instance.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';

// Also add/ configure interceptors && all the other cool stuff
instance.interceptors.request.use(
    // add JWT token to the header
    function (config) {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    }
);

instance.interceptors.response.use(res => {
      // refresh token after API access
      if (!res.config.url.includes("login") && !res.config.url.includes("refresh")) {
        //AuthenticationHandler.refresh();
      }
      return res;
    },
    function (error) {
      if (typeof error.response === 'undefined') {
        helper.sendGlobalMessage('An error occurred. Reason: '+error)
      } else {
        if (401 === error.response.status) {
          store.dispatch('authStore/logout', true);
        }
      }
      if (process.env.NODE_ENV === "development")
        console.error("api_client request rejected. Reason="+error)
      return Promise.reject(error);
    });

export default instance;